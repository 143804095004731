<template>
  <div class="py-15 px-7">
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="headers"
      :items="items"
      :loading="loading"
      class="elevation-8"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="12" class="d-flex justify-end pb-6 px-8">
            <v-btn
              color="#38227A"
              dark
              class="px-7 text-capitalize"
              style="border-radius: 10px"
              height="45px"
              @click="route('add',null)"
            >
              <span>Add new template</span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item="props">
        <tr>
          <td class="py-2 pl-6">
            <div class="tableItemHead">{{ props.item.email_title }}</div>
            <div class="tableItemSubhead">{{ props.item.email_description }}</div>
          </td>
          <td class="text-center">
            <v-chip class="actionColumn-btn" @click="route('edit',props.item.id)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-chip>
            <v-chip class="actionColumn-btn" @click="toggleDeleteModal({Delete:true,id:props.item.id})">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="icons"
                    color="#6B6B6B"
                    size="25"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <delete-modal @reload="getManualEmailTemplate()"></delete-modal>
  </div>
</template>
<script>
import { ROUTER_URL } from "@/constants/urls";
import {mapActions} from "vuex";
import Axios from "@/api/BaseAxios";
import { API_EMAIL_MANUAL_LIST } from "@/constants/APIUrls";
export default {
  name: "ManualEmailTemplate",
  components:{
    DeleteModal:()=>import("../DeleteModal/DeleteModal")
  },
  data() {
    return {
      items: [],
      email_id:null,
      loading:false,
      headers: [
        {
          sortable: false,
          text: "Email Title & Description",
          align: "left",
          class: ["tableHeader-text", "tableHeader-bg", "pl-6"],
        },
        {
          width:"20%",
          sortable: false,
          
          text: "Action",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions({
    toggleDeleteModal:"emailSettings/toggleDeleteModal"
    }),
    route(type, id) {
      if (type === "add") {
        this.$router.push({
          name: ROUTER_URL.adminPanel.children.manualEmailForm.name,
          query:{type:type}
        });
      }else{
        this.$router.push({
          name: ROUTER_URL.adminPanel.children.manualEmailForm.name,
          query:{type:type,id:id}
        });
      }
    },
    getManualEmailTemplate() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.email_template_list
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["email_template_type"] = "manual";
      Axios.request_GET(
        API_EMAIL_MANUAL_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    this.getManualEmailTemplate();
  },
};
</script>
<style scoped>
.v-btn >>> span {
  letter-spacing: 0px;
  font-weight: 600;
}
span.v-chip {
  border-radius: 4px;
}
.tableItemHead {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  /* identical to box height */
  color: #000000;
}
.tableItemSubhead {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #757575;
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
  margin-left: 10px;
  cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
